<script>
var debugMessage = "";
const moment = require("moment");
//const html2canvas = require("html2canvas");
//const jspdf = require("jspdf");

//const jspdf = require('jspdf')
//const html2canvas = require('html2canvas')
/*
import moment from "moment";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
*/

const guide = new Vue({
    el: "#app",
    props: [],
    data: {
        returnMode: "",
        purchaseType: "steps",
        purchase: "",
        mgroup: "",
        contactName: "",
        contactSID: "",
        contactPhone: "",
        students: [{ studentId: "", studentName: "", group: "", book: "" }],
        createDate: "",
        setUsers: 1,
        successMessage: null,
        errorMessage: null,
        downloadID: null,
        isSubmitting: false,
        alert711: 0,
        reqSID: null,
        reqOID: null,
        classOptions: [
            "04101都防一甲","04102都防一乙","05101電通一甲","05102電通一乙","09101數媒一甲","09102數媒一乙","11101企管一甲","11102企管一乙",
            "11103企管一丙","13101資管一甲","13102資管一乙","13103資管一丙","14101觀光一甲","15101影新一甲","17101統資一甲","18101休憩一甲",
            "18108金休憩一","19101餐旅一甲","19102餐旅一乙","21101商設一甲","21102商設一乙","22101經濟一甲","23101品設一甲","23102品設一乙",
            "24101建築一甲","26101廣銷一甲","31101新傳一甲","32101廣電一甲","32108金廣電一","32199增設班級一","33101新聞一甲","36101資工一甲",
            "36102資工一乙","36103資工一丙","36104資工一丁","37101電子一甲","37102電子一乙","38101犯防一甲","38102犯防一乙","39101生科一甲","39102生科一乙",
            "39104生科一丁","40101醫管一甲","40102醫管一乙","41101法律一甲","41102法律一乙","43101應中一甲","43103應中一丙","44101應日一甲",
            "44102應日一乙","45101華教一甲","46101金科學程一","50101動漫學程一","52101會計一甲","52102會計一乙","52103會計一丙","54101財金一甲",
            "54102財金一乙","54103財金一丙","56101風保一甲","56102風保一乙","57101國企一甲","57102國企一乙","57103國企一丙","60101心理一甲",
            "60102心理一乙","64101金融一甲","65101財法一甲","67101醫工一甲","68101公行一甲","75101人智一甲","75102人智一乙","76101半導學程一",
            "04201都防二甲","04202都防二乙","05201電通二甲","05202電通二乙","09201數媒二甲","09202數媒二乙","11201企管二甲","11202企管二乙",
            "11203企管二丙","13201資管二甲","13202資管二乙","13203資管二丙","14201觀光二甲","15201影新二甲","17201統資二甲","17202統資二乙",
            "18201休憩二甲","18208金休憩二","19201餐旅二甲","19202餐旅二乙","21201商設二甲","21202商設二乙","22201經濟二甲","22202經濟二乙",
            "23201品設二甲","23202品設二乙","24201建築二甲","26201廣銷二甲","31201新傳二甲","32201廣電二甲","32202廣電二乙","32208金廣電二",
            "32299增設班級二","33201新聞二甲","36201資工二甲","36202資工二乙","36203資工二丙","37201電子二甲","37202電子二乙","38201犯防二甲",
            "38202犯防二乙","39201生科二甲","39202生科二乙","39203生科二丙","39204生科二丁","40201醫管二甲","40202醫管二乙","41201法律二甲",
            "41202法律二乙","43201應中二甲","43202應中二乙","43203應中二丙","44201應日二甲","44202應日二乙","45201華教二甲","46201金科學程二",
            "50201動漫學程二","52201會計二甲","52202會計二乙","52203會計二丙","54201財金二甲","54202財金二乙","54203財金二丙","56201風保二甲",
            "56202風保二乙","57201國企二甲","57202國企二乙","57203國企二丙","60201心理二甲","60202心理二乙","64201金融二甲","65201財法二甲",
            "67201醫工二甲","68201公行二甲","75201人智二甲","75202人智二乙","76201半導學程二","01301商務溝通英文","01302商務溝通英文",
            "01303商務溝通英文","01304商務溝通英文","01305商務溝通英文","01306商務溝通英文","01307商務溝通英文","01308商務溝通英文",
            "01309商務溝通英文","01310商務溝通英文","01311商務溝通英文","01312商務溝通英文","01313商務溝通英文","01314商務溝通英文",
            "01316商務溝通英文","01317商務溝通英文","01318商務溝通英文","01319商務溝通英文","01320商務溝通英文","01321商務溝通英文",
            "01322商務溝通英文","01323商務溝通英文","01324商務溝通英文","01325商務溝通英文","01326商務溝通英文","01327商務溝通英文",
            "01331商務溝通英文","01332商務溝通英文","01333商務溝通英文","01334商務溝通英文","01335商務溝通英文","01336商務溝通英文",
            "01337商務溝通英文","01338商務溝通英文","01339商務溝通英文","01340商務溝通英文","01341商務溝通英文","01342商務溝通英文",
            "01343商務溝通英文","01344商務溝通英文","01345商務溝通英文","01346商務溝通英文","01347商務溝通英文","01348商務溝通英文",
            "01349商務溝通英文","01350商務溝通英文","01351商務溝通英文","01352商務溝通英文","01353商務溝通英文","01354商務溝通英文",
            "01355商務溝通英文","01356商務溝通英文","01357商務溝通英文","01358商務溝通英文","01359商務溝通英文","01360商務溝通英文",
            "01361商務溝通英文","01362商務溝通英文","01363商務溝通英文","01364商務溝通英文","01365商務溝通英文","01366商務溝通英文",
            "01367商務溝通英文","01368商務溝通英文","01369商務溝通英文","01370商務溝通英文","01371商務溝通英文","01372商務溝通英文",
            "01373商務溝通英文","01374商務溝通英文","01375商務溝通英文","01376商務溝通英文","01377商務溝通英文","01378商務溝通英文",
            "01379商務溝通英文","01399商務溝通英文","01401職場應用英文","01402職場應用英文","01403職場應用英文","01404職場應用英文",
            "01405職場應用英文","01406職場應用英文","01407職場應用英文","01408職場應用英文","01409職場應用英文","01410職場應用英文",
            "01411職場應用英文","01412職場應用英文","01413職場應用英文","01414職場應用英文","01415職場應用英文","01416職場應用英文",
            "01417職場應用英文","01418職場應用英文","01419職場應用英文","01420職場應用英文","01421職場應用英文","01422職場應用英文",
            "01423職場應用英文","01424職場應用英文","01425職場應用英文","01426職場應用英文","01427職場應用英文","01431職場應用英文",
            "01432職場應用英文","01433職場應用英文","01434職場應用英文","01435職場應用英文","01436職場應用英文","01437職場應用英文",
            "01438職場應用英文","01439職場應用英文","01441職場應用英文","01442職場應用英文","01443職場應用英文","01444職場應用英文",
            "01446職場應用英文","01447職場應用英文","01448職場應用英文","01449職場應用英文","01450職場應用英文","01451職場應用英文",
            "01452職場應用英文","01453職場應用英文","01454職場應用英文","01455職場應用英文","01456職場應用英文","01457職場應用英文",
            "01458職場應用英文","01459職場應用英文","01460職場應用英文","01461職場應用英文","01462職場應用英文","01463職場應用英文",
            "01464職場應用英文","01465職場應用英文","01466職場應用英文","01467職場應用英文","01468職場應用英文","01469職場應用英文",
            "01470職場應用英文","01471職場應用英文","01472職場應用英文","01491職場應用英文","01492職場應用英文","01493職場應用英文",
            "01499職場應用英文","24401職場應用英文"],
    },
    methods: {
        /*
        purchase: function (priVal) {
            //console.log(priVal);
            window.location.href = '/guide/' + priVal;
                return;
        },
        */
        search() {
            const trimmedQuery = this.students.group.trim();

            // 若未 focus，則不搜尋
            if (!this.isFocused) return;

            // 若輸入少於 3 個字，不搜尋
            if (trimmedQuery.length < 3) {
                this.results = [];
                return;
            }

            // 若輸入完全匹配現有的班級，不搜尋
            if (this.classes.includes(trimmedQuery)) {
                this.results = [];
                return;
            }

            // 執行搜尋，篩選包含輸入內容的班級
            this.results = this.classes.filter((item) =>
                item.includes(trimmedQuery)
            );
        },
        clearResults() {
            this.isFocused = false;
            this.results = [];
        },
        handleTableClass(event) {
            //console.log('Clicked Element:', event.target);
            if (event.target.tagName === "TD") {
                this.mgroup = event.target.textContent;
                this.updateGroup();
                $(".modal").modal("hide");
            }
        },
        addStudent() {
            if (this.students.length >= 33 && this.alert711 === 0) {
                alert("便利商店繳款上限為2萬元，繼續增加僅能使用ATM繳款。");
                this.alert711 = 1;
                return;
            }

            if (this.students.length >= 50) {
                alert("ATM 單次轉帳上限為 3萬元，請務必注意。");
                return;
            }

            this.students.push({
                studentId: "",
                studentName: "",
                group: "",
                book: "",
            });
        },
        removeStudent(xIndex) {
            switch (this.purchaseType) {
                case "term1":
                    break;
                case "term2":
                    break;
                case "termU":
                    if (Object.keys(this.students).length > 1) {
                        this.students.splice(xIndex, 1);
                    } else {
                        alert("個人購買/重補修，至少需要 1個班級以上。");
                    }
                    break;
                case "termX":
                    if (Object.keys(this.students).length > 10) {
                        this.students.splice(xIndex, 1);
                    } else {
                        alert("團體購買，至少需要 10個人以上。");
                    }
                    break;
                case "preview":
                    break;
                default:
                    break;
            }
        },
        updateGroup() {
            this.students.forEach((student) => {
                if (student.group === "") {
                    student.group = this.mgroup;
                }
            });
        },
        updateSID() {
            this.students.forEach((student) => {
                student.studentId = this.contactSID;
            });
        },
        updateName() {
            this.students.forEach((student) => {
                student.studentName = this.contactName;
            });
        },
        setAllTo650() {
            this.students.forEach((student) => {
                student.book = "650"; // 設定全部 book 欄位為 650
            });
        },
        setAllTo600() {
            this.students.forEach((student) => {
                student.book = "600"; // 設定全部 book 欄位為 600
            });
        },
        returnPreview() {
            if (!this.createDate) {
                this.createDate = moment().format("YYYYMMDDHHmm");
            }
            this.purchaseType = this.purchase;
            this.errorMessage = null;
            this.successMessage = null;
        },
        genPreview() {
            event.preventDefault();
            // 在此處向伺服器提交帖子資料
            if (!this.createDate) {
                this.createDate = moment().format("YYYYMMDDHHmm");
            }
            // 在這裡可以執行 POST 請求或其他處理
            // 示例中只是簡單地將預覽模式切換為 true
            switch (this.purchaseType) {
                case "term1":
                    this.purchase = this.purchaseType;
                    this.purchaseType = "preview";
                    break;
                case "term2":
                    this.purchase = this.purchaseType;
                    this.purchaseType = "preview";
                    break;
                case "termU":
                    this.purchase = this.purchaseType;
                    this.purchaseType = "preview";
                    break;
                case "termX":
                    /*
                        if (Object.keys(this.students).length <10 ){
                            alert("團體購買，至少需要 10個人以上。");
                            return;
                        }
                        */
                    this.students.forEach((student) => {
                        student.group = mgroup.value;
                    });
                    this.purchase = this.purchaseType;
                    this.purchaseType = "preview";
                    break;
                case "preview":
                    break;
                default:
                    break;
            }
            //console.log("data:", this.students);
        },
        saveData() {
            document.getElementById("sendSpinner").classList.remove("d-none");

            // 檢查是否已經在提交中
            if (this.isSubmitting) {
                return;
            }
            // 設置提交狀態
            this.isSubmitting = true;
            // 獲取 CSRF 令牌
            const csrfToken = document.head.querySelector(
                'meta[name="csrf-token"]'
            ).content;

            // 將 CSRF 令牌新增到 Axios 要求標頭中
            axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

            // 傳送 POST 請求
            axios
                .post("/api/order/store", {
                    // 這裡新增要傳送的資料
                    oid: this.createDate,
                    type: this.purchase,
                    group: this.mgroup,
                    name: this.contactName,
                    stuid: this.contactSID,
                    phone: this.contactPhone,
                    orders: this.students,
                })
                .then((response) => {
                    // 處理成功響應
                    this.successMessage = response.data.message;
                    this.errorMessage = null;
                    this.downloadID = response.data.downloadID;
                    // 重設表單或執行其他操作
                })
                .catch((error) => {
                    // 處理錯誤響應
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                    ) {
                        this.errorMessage = error.response.data.error;
                    } else {
                        this.errorMessage = "發生了一個錯誤，請重試。";
                    }
                })
                .finally(() => {
                    // 無論成功或失敗，都重設提交狀態
                    document
                        .getElementById("sendSpinner")
                        .classList.add("d-none");
                    this.isSubmitting = false;
                });
        },
        reDownload() {
            if (
                this.reqSID === "" ||
                this.reqOID === "" ||
                (this.reqSID && this.reqSID.length < 8) ||
                (this.reqOID && this.reqOID.length < 12)
            ) {
                alert("請檢查學號或訂單編號格式是否正確");
                return;
            }

            // 產生下載連結的 URL
            const url = `/guide/downloads/${this.reqSID}/${this.reqOID}`;

            // 使用 Axios 來發送 GET 請求並觸發檔案下載
            axios
                .get(url, { responseType: "blob" })
                .then((response) => {
                    // 獲取檔案名稱 (從後端的 Content-Disposition header 或 URL 中獲取)
                    //const disposition = response.headers["content-disposition"];
                    //const filename = disposition ? disposition.split("filename=")[1].split(";")[0] : `${this.reqSID}-${this.reqOID}.docx`;
                    const disposition = response.headers["content-disposition"];
                    let filename = "document.docx"; // 預設文件名
                    if (
                        disposition &&
                        disposition.indexOf("filename=") !== -1
                    ) {
                        let matches = disposition.match(
                            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                        );
                        if (matches != null && matches[1]) {
                            // 提取文件名，並去掉文件路徑（若存在）
                            filename = matches[1].replace(/['"]/g, ""); // 去掉引號
                            filename = filename.split("/").pop(); // 如果是完整路徑，則僅保留最後的文件名
                        }
                    }

                    // 創建 Blob 並生成下載鏈接
                    const blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();

                    // 移除鏈接
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href); // 立即銷毀對象 URL
                })
                .catch((error) => {
                    // 處理錯誤
                    console.error("下載失敗", error);
                    alert("無效的學號或訂單編號，無法取得檔案。");
                });
        },
        checkOrder() {},
        genDOCnSave() {
            // 顯示下載中的圖示
            document.getElementById("docSpinner").classList.remove("d-none");
            const csrfToken = document.head.querySelector(
                'meta[name="csrf-token"]'
            ).content;

            // 將 CSRF 令牌新增到 Axios 要求標頭中
            axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
            axios
                .post(
                    "/guide/download",
                    {
                        // 這裡新增要傳送的資料
                        stuid: this.contactSID,
                        dlid: this.downloadID,
                        filetype: "doc",
                    },
                    {
                        responseType: "blob", // 明確指定響應類型為 blob
                    }
                )
                .then((response) => {
                    const disposition = response.headers["content-disposition"];
                    const filename = disposition
                        ? disposition.split("filename=")[1].split(";")[0]
                        : "document.docx";

                    // 處理成功響應
                    const blob = new Blob([response.data], {
                        type:
                            response.headers["content-type"] ||
                            "application/octet-stream",
                    });

                    // 建立一個連結
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = filename; // 設定下載檔案名稱
                    a.style.display = "none";

                    // 將 <a> 元素新增到頁面
                    document.body.appendChild(a);

                    // 模擬點選 <a> 元素
                    a.click();

                    // 釋放 URL 和移除 <a> 元素
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    document
                        .getElementById("docSpinner")
                        .classList.add("d-none");
                })
                .catch((error) => {
                    console.error("檔案下載失敗：", error);
                    document
                        .getElementById("docSpinner")
                        .classList.add("d-none");
                });
        },
        genPDFnSave() {
            // 顯示下載中的圖示
            document.getElementById("pdfSpinner").classList.remove("d-none");
            const csrfToken = document.head.querySelector(
                'meta[name="csrf-token"]'
            ).content;

            // 將 CSRF 令牌新增到 Axios 要求標頭中
            axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
            axios
                .post(
                    "/guide/download",
                    {
                        // 這裡新增要傳送的資料
                        stuid: this.contactSID,
                        dlid: this.downloadID,
                        filetype: "pdf",
                    },
                    {
                        responseType: "blob", // 明確指定響應類型為 blob
                    }
                )
                .then((response) => {
                    const disposition = response.headers["content-disposition"];
                    const filename = disposition
                        ? disposition.split("filename=")[1].split(";")[0]
                        : "document.pdf";

                    // 處理成功響應
                    const blob = new Blob([response.data], {
                        type:
                            response.headers["content-type"] ||
                            "application/octet-stream",
                    });

                    // 建立一個連結
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = filename; // 設定下載檔案名稱
                    a.style.display = "none";

                    // 將 <a> 元素新增到頁面
                    document.body.appendChild(a);

                    // 模擬點選 <a> 元素
                    a.click();

                    // 釋放 URL 和移除 <a> 元素
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    document
                        .getElementById("pdfSpinner")
                        .classList.add("d-none");
                })
                .catch((error) => {
                    console.error("檔案下載失敗：", error);
                    document
                        .getElementById("pdfSpinner")
                        .classList.add("d-none");
                });
        },
        gen711nSave() {
            // 顯示下載中的圖示
            document.getElementById("711Spinner").classList.remove("d-none");
            const csrfToken = document.head.querySelector(
                'meta[name="csrf-token"]'
            ).content;

            // 將 CSRF 令牌新增到 Axios 要求標頭中
            axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
            axios
                .post(
                    "/guide/download/barcode",
                    {
                        // 這裡新增要傳送的資料
                        stuid: this.contactSID,
                        dlid: this.downloadID,
                        filetype: "711",
                    },
                    {
                        responseType: "blob", // 明確指定響應類型為 blob
                    }
                )
                .then((response) => {
                    const disposition = response.headers["content-disposition"];
                    const filename = disposition
                        ? disposition.split("filename=")[1].split(";")[0]
                        : "document.pdf";

                    // 處理成功響應
                    const blob = new Blob([response.data], {
                        type:
                            response.headers["content-type"] ||
                            "application/octet-stream",
                    });

                    // 建立一個連結
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = filename; // 設定下載檔案名稱
                    a.style.display = "none";

                    // 將 <a> 元素新增到頁面
                    document.body.appendChild(a);

                    // 模擬點選 <a> 元素
                    a.click();

                    // 釋放 URL 和移除 <a> 元素
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    document
                        .getElementById("711Spinner")
                        .classList.add("d-none");
                })
                .catch((error) => {
                    console.error("檔案下載失敗：", error);
                    document
                        .getElementById("711Spinner")
                        .classList.add("d-none");
                });
        },
        /*
        PE34: function (priVal) {
            console.log("PE34");
        },
        PE56: function (priVal) {
            console.log("PE56");
        },
        PE78: function (priVal) {
            console.log("PE78");
        }
        */
        changeType(newVal) {
            this.purchase = "";
            this.mgroup = "";
            this.contactName = "";
            this.contactSID = "";
            this.contactPhone = "";
            this.students = [
                { studentId: "", studentName: "", group: "", book: "" },
            ];
            this.errorMessage = null;
            this.successMessage = null;
            this.downloadID = null;
            switch (newVal) {
                case "term1":
                    this.setUsers = 1;
                    this.students = [
                        {
                            studentId: "",
                            studentName: "",
                            group: "",
                            book: 350,
                        },
                    ];
                    break;
                case "term2":
                    this.setUsers = 1;
                    this.students = [
                        {
                            studentId: "",
                            studentName: "",
                            group: "",
                            book: 650,
                        },
                    ];
                    break;
                case "termU":
                    this.setUsers = 1;
                    break;
                case "termX":
                    this.setUsers = 10;
                    for (var idx = 1; idx < this.setUsers; idx++) {
                        this.addStudent();
                    }
                    break;
                case "check":
                    window.location.href = "/account";
                    break;
                case "checkOrDL":
                    break;
                default:
                    window.location.href = "/guide";
                    break;
            }
        },
    },
    created: function () {
        this.purchaseType = "steps";
    },
    updated: function () {
        //console.log('updated');
    },
    computed: {
        totalBookPrice() {
            return this.students.reduce(
                (total, student) => total + parseInt(student.book),
                0
            );
        },

        filteredClassOptions() {
            // 找到所有輸入長度超過 3 碼的 group，篩選匹配班級
            let searchTerm = this.students.map(student => student.group).find(group => group.length >= 3) || '';
            if (searchTerm.length < 3) return [];

            return this.classOptions.filter(className => className.includes(searchTerm));
        },

        /*
        syncedStudents() {
            return this.students.map(student => ({
                group: this.mgroup,
            }));
        },
        */
    },
    watch: {
        /*
        purchaseType(newVal, oldVal) {
            if (newVal.length !== oldVal){
                this.purchase= "";
                this.mgroup= "";
                this.contactName= "";
                this.contactSID="";
                this.contactPhone= "";
                this.students= [{ studentId:"", studentName: "", group: "", book: "" }];

                switch (this.purchaseType) {
                case "term1":
                    this.students= [{ studentId:"", studentName: "", group: "", book: 350 }];
                    break;
                case "term2":
                    this.students= [{ studentId:"", studentName: "", group: "", book: 580 }];
                    break;
                case "termU":

                    break;
                case "termX":

                    break;
                case "preview":
                    break;
                default:
                    break;
                }
            }
        },
        */
        // organize is organiaze ID
        /*
        ,
        preid: (newVal, oldVal) => {
            if (newVal != oldVal) {
                userregister.getOrganize(newVal);
            }
        }
        */
    },
    mounted() {
        //this.getOrganizeList();
        $("#startTip").modal("show");
    },
});
</script>
